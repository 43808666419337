import React from 'react'
import './Experience.css'
import { Fade } from 'react-reveal'
import Section from '../section/Section'
import ExperienceCard from '../experienceCard/ExperienceCard'
import experienceData from '../../data/experience.json'
import experienceData2 from '../../data/experience2.json'

const Experience = ({
  language
}) => {
  return (
    <Section title={language === 0 ? "Experiencia" : "Experience"}>
      <div className="experience-content">
        <ul className="experience-list">
          {
            language === 0 ?
              experienceData.experience.reverse().map((exp, key) => (
                <li key={key} >
                  <Fade bottom duration={1000} distance="20px">
                    <ExperienceCard experience={exp} />
                  </Fade>
                </li>
              )) :
              experienceData2.experience.reverse().map((exp, key) => (
                <li key={key}>
                  <Fade bottom duration={1000} distance="20px">
                    <ExperienceCard experience={exp} />
                  </Fade>
                </li>
              ))
          }
        </ul>
        <Fade bottom duration={1200} distance="20px">
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
          </div>
        </Fade>
      </div>
    </Section>
  )
}

export default Experience
