import React, { useState } from 'react'
import './App.css'
import Home from './components/home/Home'
import About from './components/about/About'
import Experience from './components/experience/Experience'
import Projects from './components/projects/Projects'
import Contact from './components/contact/Contact'
import Footer from './components/footer/Footer'
import TopButton from './components/topButton/TopButton'

const App = () => {
  const [language, setLanguage] = useState(0)

  return (
    <div className="App">
      <Home
        setLanguage={setLanguage}
        language={language}
      />
      <About
        language={language}
      />
      <Experience
        language={language}
      />
      <Projects
        language={language}
      />
      <Contact
        language={language}
      />
      <Footer
        language={language}
      />
      <TopButton
        language={language}
      />
    </div>
  )
}

export default App
