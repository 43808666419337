import React, { useRef, useState } from 'react'
import './ContactForm.css'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import axios from 'axios'
import { css } from "@emotion/react"
import ClipLoader from "react-spinners/ClipLoader"
import ReCAPTCHA from "react-google-recaptcha"

const useStyles = makeStyles((theme) => ({
  email: {
    '& > *': {
      marginBottom: theme.spacing(2),
      backgroundColor: '#3b4353',
      color: 'white',
      '&:hover': {
        backgroundColor: '#3b4353',
        color: 'white',
      },
      '&.Mui-focused': {
        backgroundColor: '#3b4353',
        color: 'white',
      },
      '&.MuiFilledInput-underline:before': {
        borderBottom: '2px solid #6f7b9b',
      },
      '&.MuiFilledInput-underline:after': {
        borderBottom: '2px solid #258b9e',
      },
    },
  },
  message: {
    '& > *': {
      marginBottom: theme.spacing(2),
      backgroundColor: '#3b4353',
      color: 'white',
      '&:hover': {
        backgroundColor: '#3b4353',
        color: 'white',
      },
      '&.Mui-focused': {
        backgroundColor: '#3b4353',
        color: 'white',
      },
      '&.MuiFilledInput-underline:before': {
        borderBottom: '2px solid #6f7b9b',
      },
      '&.MuiFilledInput-underline:after': {
        borderBottom: '2px solid #258b9e',
      },
    },
  },
  submit: {
    '&': {
      backgroundColor: '#f9dd3c',
      boxShadow: 'none',
      '&:hover': {
        backgroundColor: '#c6ad21',
        boxShadow: 'none',
      },
    },
    '& > *': {
      color: 'black',
      fontSize: '15px',
      fontWeight: '600',
    },
  },
}))

const ContactForm = ({
  language
}) => {
  const recaptchaRef = useRef()

  const [status, setStatus] = useState('')
  const [emailText, setEmailText] = useState('')
  const [messageText, setMessageText] = useState('')
  const [loading, setLoading] = useState(false)

  const classes = useStyles()

  const override = css`
  display: block;
  margin: 0 auto;
  border-color: #f9dd3c;
`;

  const submitForm = async (ev) => {
    const form = ev.target
    ev.preventDefault()
    const value = await recaptchaRef.current.executeAsync()
    const data = {
      email: emailText,
      message: messageText
    }
    setLoading(true)
    await axios.post(`https://api-prod.nekoadmin.com.ar/jretondo/email`, data, {
      headers: {
        'Authorization': 'Bearer ' + value
      }
    })
      .then(() => {
        setLoading(false)
        setEmailText('')
        setMessageText('')
        form.reset()
        setStatus('SUCCESS')
        setTimeout(() => {
          setStatus("")
        }, 2000);
      })
      .catch(() => {
        setLoading(false)
        setStatus('ERROR')
        setTimeout(() => {
          setStatus("")
        }, 2000);
      })
  }

  const handleEmailChange = (event) => {
    const input = String(event.target.value)
    setEmailText(input)
  }

  const handleMessageChange = (event) => {
    const input = String(event.target.value)
    setMessageText(input)
  }

  return (
    <div className="contact-form-wrapper">
      <form
        className="contact-form"
        onSubmit={submitForm}
      >
        <div style={{ zIndex: 99999 }} >
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey="6Ld29A8eAAAAADpbVS8ohFSMoAvCtu8Xei994T90"
            size="invisible"
            badge="bottomleft"
          />
        </div>
        {
          loading ?
            <ClipLoader color={"#fff"} loading={loading} css={override} size={150} /> :
            <>
              <TextField
                className={classes.email}
                type="email"
                name="email"
                label="Email"
                value={emailText}
                onChange={handleEmailChange}
                variant="filled"
              />
              <TextField
                className={classes.message}
                type="text"
                name="message"
                label={language === 0 ? "Mensaje" : "Message"}
                value={messageText}
                onChange={handleMessageChange}
                multiline
                rows="5"
                variant="filled"
              />
              {status === 'SUCCESS' ? (
                <p className="email-success">{language === 0 ? "Mensaje enviado! Gracias! Pronto me contactaré contigo" : "Message sended! Thanks! I will contact you soon"}</p>
              ) : (
                <Button className={classes.submit} type="submit" variant="contained">
                  {language === 0 ? "Enviar" : "Submit"}
                </Button>
              )}
            </>
        }
        {status === 'ERROR' && <p>{language === 0 ? "Ups! Hubo un error inesperado." : "Ooops! There was an error."}</p>}
      </form>
    </div >
  )
}

export default ContactForm
