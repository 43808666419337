import React, { useState } from 'react'
import './About.css'
import Typewriter from 'typewriter-effect'
import { Fade } from 'react-reveal'
import Section from '../section/Section'
import Skills from '../skills/Skills'

const About = ({ language }) => {
  const [showHistory, setShowHistory] = useState(false)

  return (
    <Section title={language === 0 ? 'Sobre mí' : 'About'}>
      <div className="about-content">
        <Fade duration={1000}>
          <div className="about-text">
            <h2>{language === 0 ? '¿Quién soy?' : 'Who am I?'}</h2>
            <p>
              {language === 0 ? 'Yo soy Javier Retondo' : "I'm Javier Retondo"}{' '}
              <span role="img" aria-label="lightning">
                😄
              </span>{' '}
            </p>
            <p>
              <span role="img" aria-label="lightning">
                ⚡
              </span>{' '}
              {language === 0 ? (
                <span>
                  Soy desarrollador fullstack en JavaScript, estudiante de la
                  carrera Técnico Universitario en Desarrollo de Aplicaciones
                  Informáticas (TUDAI) en la Instituto Universitario Aeronáutico
                  y fundador del empredimiento{' '}
                  <a
                    href="https://nekonet.com.ar"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: '#f9dd3c' }}
                  >
                    NekoNET
                  </a>
                </span>
              ) : (
                <span>
                  I'm a fullstack JavaScript developer, a student in the
                  University Technician in Computer Application Development
                  program at the Aeronautical University Institute, and the
                  founder of the startup{' '}
                  <a
                    href="https://nekonet.com.ar"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: '#f9dd3c' }}
                  >
                    NekoNET
                  </a>
                </span>
              )}
            </p>
            <p>
              <span role="img" aria-label="lightning">
                ⚡
              </span>{' '}
              {language === 0 ? (
                <span>
                  Actualmente me especializo en las tecnologías{' '}
                  <span style={{ color: '#f9dd3c' }}>
                    ReactJS, NodeJS con Express y TypeScript
                  </span>{' '}
                  , aunque también poseo conocimientos en otras tecnologías
                  tanto para aplicaciones de escritorio como para web.
                </span>
              ) : (
                <span>
                  Nowadays, I specialize in technologies such as{' '}
                  <span style={{ color: '#f9dd3c' }}>
                    ReactJS, NodeJS with Express and TypeScript
                  </span>
                  , although I have knowledge about other technologies for desk
                  application and web applications too.
                </span>
              )}
            </p>
            {language === 0 ? (
              <div className="typewriter">
                <p className="typewriter-start">
                  <span role="img" aria-label="lightning">
                    ⚡
                  </span>{' '}
                  Disfruto
                </p>
                <Typewriter
                  options={{
                    strings: [
                      'aprender de mis compañeros',
                      'compartir conocimientos',
                      'resolver problemas complejos',
                      'los retos',
                      'conocer nuevas personas',
                      'ayudar a crear sueños',
                    ],
                    autoStart: true,
                    loop: true,
                  }}
                />
                <p>.</p>
              </div>
            ) : (
              <div className="typewriter">
                <p className="typewriter-start">
                  <span role="img" aria-label="lightning">
                    ⚡
                  </span>{' '}
                  I Love
                </p>
                <Typewriter
                  options={{
                    strings: [
                      'learning from my partners',
                      'sharing knowledge',
                      'solving complex problems',
                      'new challenges',
                      'meeting to new people',
                      'helping to make dreams',
                    ],
                    autoStart: true,
                    loop: true,
                  }}
                />
                <p>.</p>
              </div>
            )}
          </div>
        </Fade>
        <Skills language={language} />
      </div>
      {language === 0 ? (
        <h3>Historia como desarrollador</h3>
      ) : (
        <h3>Story as developer</h3>
      )}
      {language === 0 ? (
        showHistory ? (
          <>
            <p>
              En 2013 a través de la universidad (Universidad Tecnológica
              Nacional), ví por primera vez las posibilidades de la programación
              aplicando algoritmos y lógica en la herramienta Matlab. Luego por
              cuenta propia (autodidacta) empecé a utilizar el lenguaje VB6 y
              VBA dentro de Excel, a las misma la he utilizado en la empresa
              donde trabajo. Una vez avanzado en el conocimiento de Visual
              Basic, empecé a usar VB.Net 2005 y luego VB.Net 2012, con esta
              última pude empezar a crear aplicaciones de escritorio con Forms y
              cobrar como freelance por ellas, aproximadamente en el año 2015
              cobré mi primera aplicación.{' '}
            </p>
            <p>
              En mis inicios utilizaba como base de datos Access de MS de forma
              local y al ver la limitación que tenía, me vi forzado a aprender
              MySQL para dar mejor experiencia de usuario y organizar mejor la
              información. Con esto, también tuve que aprender Debian 8 para
              poder utilizarlo como servidor, al mismo lo he montado de inicio a
              fin en mi domicilio.
            </p>
            <p>
              En los años posteriores empecé a interesarme en el desarrollo web,
              comenzando a aprender HTML, CSS y JQuery. Al verme con la
              necesidad de realizar aplicaciones web, también aprendí en esos
              años PHP 7. Paralelamente empecé a incursionar en el mundo de los
              frameworks y librerías, y en el año 2017 empecé con cursos sobre
              ReactJS y en el 2018 ya pude aplicarlo a proyectos reales de
              clientes.
            </p>
            <p>
              En el 2018 empecé a interesarme en NodeJS, y comencé con algunos
              cursos y a migrar algunas aplicaciones de backend de PHP a NodeJS.
              En 2020 con NekoNET empiezo a adquirir clientes por fuera del
              entorno de conocidos y familiares.
            </p>
            <p>
              En 2021 empiezo y termino varios cursos para profundizar aún más
              en ReactJS, NodeJs y en la programación en general (Redux, Router,
              TypeScript, Git & GitHub, entre otros). También continuo con mis
              estudios en el inglés de forma particular con profesora
              universitaria de la Universidad Nacional de Córdoba.{' '}
            </p>
            <p>
              Recientemente, me he estado enfocando en el desarrollo general de
              software, aprendiendo los fundamentos de la arquitectura de
              software y patrones de diseño, en lugar de centrarme en
              tecnologías específicas.{' '}
            </p>
            <p>
              Mi objetivo principal en el desarrollo de software es poder seguir
              avanzando en mi aprendizaje para poder desenvolverme como
              arquitecto de software el día de mañana.
            </p>
            <a
              href="#5"
              style={{ color: '#f9dd3c', textDecoration: 'none' }}
              onClick={(e) => {
                e.preventDefault()
                setShowHistory(false)
              }}
            >
              leer menos
            </a>
          </>
        ) : (
          <p>
            En 2013 a través de la universidad (Universidad Tecnológica
            Nacional), ví por primera vez las posibilidades de la programación
            aplicando algoritmos y lógica en la herramienta Matlab. Luego por
            cuenta propia (autodi ......{' '}
            <a
              href="#5"
              style={{ color: '#f9dd3c', textDecoration: 'none' }}
              onClick={(e) => {
                e.preventDefault()
                setShowHistory(true)
              }}
            >
              leer más
            </a>
          </p>
        )
      ) : showHistory ? (
        <>
          <p>
            In 2013, through National Technological University, I saw for the
            first time the possibilities of programming using a tool called
            Matlab. Then, I started as an autodidact with VB6 and VBA inside
            Excel. Those were used by me in the company where I work. When I
            advanced in Visual Basic, I started to use VB.Net 2005 and then
            VB.Net 2012. With the last one, I could charge my services as a
            freelance developer. I charged for the first time in 2015.{' '}
          </p>
          <p>
            In my early years, I used Access MS as a database but it has a big
            limitation; therefore, I changed it for MySQL. With the last one, I
            can give the clients the best experience with databases. For that
            reason, I had to learn Debian 8 from Linux and I used it as a
            database server, which I set up completely at home.
          </p>
          <p>
            Then, I began to be interested in Web Development and started to
            learn HTML, CSS and JQuery. As I needed more functionality, I
            learned PHP 7. Meanwhile, I began to see frameworks and libraries.
            After that, I started to learn ReactJS in some courses and then I
            used it in my new projects.
          </p>
          <p>
            In 2018, I started to learn NodeJS in courses and I migrated code
            made in PHP to NodeJS. Then, in 2020 with NekoNET I got clients
            through marketing or my web page.
          </p>
          <p>
            In 2021, I ended a lot of courses about ReactJS, NodeJS and others
            (Redux, Router, TypeScript, Git & GitHub, and so forth) and I am
            still improving my English with private lessons with a teacher
            graduated from the National University of Córdoba.
          </p>
          <p>
            Recently, I have been focusing more on general software development,
            learning the fundamentals of software architecture and design
            patterns, rather than concentrating on specific technologies.
          </p>
          <p>
            My main goal in software development is to keep advancing in my
            learning so that I can work as a software architect in the future.
          </p>
          <a
            href="#5"
            style={{ color: '#f9dd3c', textDecoration: 'none' }}
            onClick={(e) => {
              e.preventDefault()
              setShowHistory(false)
            }}
          >
            read less
          </a>
        </>
      ) : (
        <p>
          In 2013, through National Technological University, I saw for the
          first time the possibilities of programming using a tool called
          Matlab. Then, I started as an autodidi ......{' '}
          <a
            href="#5"
            style={{ color: '#f9dd3c', textDecoration: 'none' }}
            onClick={(e) => {
              e.preventDefault()
              setShowHistory(true)
            }}
          >
            read more
          </a>
        </p>
      )}
      <div className="location-wrapper">
        <svg
          className="octicon octicon-location"
          viewBox="0 0 16 16"
          version="1.1"
          width="16"
          height="16"
          aria-hidden="true"
        >
          <path
            fill="white"
            fillRule="evenodd"
            d="M11.536 3.464a5 5 0 010 7.072L8 14.07l-3.536-3.535a5 5 0 117.072-7.072v.001zm1.06 8.132a6.5 6.5 0 10-9.192 0l3.535 3.536a1.5 1.5 0 002.122 0l3.535-3.536zM8 9a2 2 0 100-4 2 2 0 000 4z"
          ></path>
        </svg>
        <p>Córdoba, Argentina</p>
      </div>
    </Section>
  )
}

export default About
