import React from 'react'
import './Contact.css'
import Section from '../section/Section'
import { Bounce } from 'react-reveal'
import ContactForm from '../contactForm/ContactForm'
import linkedin from '../../images/social/linkedin.png'
import github from '../../images/social/github.png'
import instagram from '../../images/social/instagram.png'

const Contact = ({
  language
}) => {
  return (
    <Section title={language === 0 ? "Contacto" : "Contact"}>
      <ContactForm
        language={language}
      />
      <Bounce cascade>
        <div className="links">
          <a
            href="https://github.com/jretondo"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={github} alt="Github Logo" width="40px" />
          </a>
          <a
            href="https://www.linkedin.com/in/dev-retondo-javier/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={linkedin} alt="Linkedin Logo" width="40px" />
          </a>

          <a
            href="https://www.instagram.com/javiretondo/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={instagram} alt="Instagram Logo" width="40px" />
          </a>
        </div>
      </Bounce>
    </Section>
  )
}

export default Contact
