import React, { useEffect, useState } from 'react'
import './Home.css'
import Fade from 'react-reveal/Fade'
import { Bounce } from 'react-reveal'
import { Link } from 'react-scroll'
import Particles from 'react-particles-js'
import Typewriter from 'typewriter-effect'
import ArrowDropDownCircleIcon from '@material-ui/icons/ArrowDropDownCircle'
import Navbar from '../navbar/Navbar'
import config from '../../config'
import profile from '../../images/profile.png'
import linkedin from '../../images/social/linkedin.png'
import ReactCountryFlag from "react-country-flag"
import visitorInfo from 'visitor-info'
import axios from 'axios'

const Home = ({ setLanguage, language }) => {

  useEffect(() => {
    sendVisitorInfo();
  }, [])

  const [imageLoaded, setImageLoaded] = useState(false)

  const sendVisitorInfo = async (value) => {
    const data = {
      dataVisitor: visitorInfo()
    }
    await axios.post(`https://api-prod.nekoadmin.com.ar/jretondo/visitors`, data)
      .then(() => { })
      .catch((error) => {
        console.error(error)
      })
  }

  return (
    <>
      <div style={{ zIndex: 99999 }} >
      </div>
      <div className="home-wrapper">
        <div className="home">
          <div className="dropdown">
            <button className="dropbtn">{language === 0 ? "Idioma" : "Language"}</button>
            <div className="dropdown-content">
              <a href="#a" onClick={e => {
                e.preventDefault();
                setLanguage(0);
              }}><ReactCountryFlag countryCode="ES" />{" "} Español</a>
              <a href="#a" onClick={e => {
                e.preventDefault();
                setLanguage(1);
              }}><ReactCountryFlag countryCode="GB" />{" "} English</a>
            </div>
          </div>
          <Particles className="particles" params={config.particles} />
          <div className={`greeting${!imageLoaded ? ' hide' : ''}`}>
            <Fade bottom distance="40px">
              <img
                className="profile"
                alt="m.jigalin profile"
                src={profile}
                onLoad={() => setImageLoaded(true)}
              />
              <h1 className="greeting-text">
                {language === 0 ? "Hola, soy" : "Hi, I'm"} <span className="name">Javier Retondo</span>.{' '}
                <span className="wave-emoji" role="img" aria-label="waving hand">
                  👋
                </span>
              </h1>
              <h1 className="greeting-text">
                {
                  language === 0 ?
                    <Typewriter
                      options={{
                        strings: [
                          'Me gusta crear.',
                          'Nunca paro de aprender.',
                          'Mi fuerte es el trabajo en equipo.',
                          'Soluciono problemas.',
                          'Creo las mejores experiencias digitales.',
                        ],
                        autoStart: true,
                        loop: true,
                        deleteSpeed: 10,
                        cursor: '|',
                        delay: 100,
                      }}
                    /> :
                    <Typewriter
                      options={{
                        strings: [
                          'I like to design things.',
                          'I never stop learning.',
                          'My principal skill, teamwork.',
                          'I solve problems.',
                          'I create the best digital experiences.',
                        ],
                        autoStart: true,
                        loop: true,
                        deleteSpeed: 10,
                        cursor: '|',
                        delay: 100,
                      }}
                    />
                }
              </h1>
              <Bounce cascade>
                <div className="links">
                  <a
                    href="https://www.linkedin.com/in/dev-retondo-javier/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={linkedin} alt="Linkedin Logo" width="50px" />
                  </a>
                </div>
              </Bounce>
              <div className="scroll-down">
                <Link
                  activeclass="active"
                  to={language === 0 ? "sobre mí" : "about"}
                  spy={true}
                  smooth={true}
                  offset={-63}
                  duration={500}
                >
                  <ArrowDropDownCircleIcon
                    fontSize="large"
                    style={{ pointerEvents: 'fill', cursor: 'pointer' }}
                  />
                </Link>
              </div>
            </Fade>
          </div>
          <Navbar
            language={language}
          />
        </div>
      </div>
    </>
  )
}

export default Home
