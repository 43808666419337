import React from 'react'
import './Footer.css'

const Footer = ({
  language
}) => {
  return (
    <footer>
      <p className="copyright">
        {language === 0 ? "Realizado por" : "Made by"} Javier Retondo
      </p>
    </footer>
  )
}

export default Footer
