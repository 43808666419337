import React from 'react'
import './MobileNav.css'
import { Link } from 'react-scroll'

const MobileNav = ({ toggleMobilenavVisible, mobilenavVisible, language }) => {
  return (
    <div className="mobilenav-wrapper">
      <div className={'mobilenav ' + (mobilenavVisible ? 'on' : 'off')}>
        <div className="mobilenavlinks">
          <div className="mobilenavlink">
            <Link
              activeClass="active"
              to="home"
              spy={true}
              smooth={true}
              duration={500}
              onClick={toggleMobilenavVisible}
            >
              HOME
            </Link>
          </div>
          <div className="mobilenavlink">
            <Link
              activeClass="active"
              to={language === 0 ? "sobre mí" : "about"}
              spy={true}
              smooth={true}
              offset={1}
              duration={500}
              onClick={toggleMobilenavVisible}
            >
              {language === 0 ? "SOBRE MI" : "ABOUT"}
            </Link>
          </div>
          <div className="mobilenavlink">
            <Link
              activeClass="active"
              to={language === 0 ? "experiencia" : "experience"}
              spy={true}
              smooth={true}
              duration={500}
              onClick={toggleMobilenavVisible}
            >
              {language === 0 ? "EXPERIENCIA" : "EXPERIENCE"}
            </Link>
          </div>
          <div className="mobilenavlink">
            <Link
              activeClass="active"
              to={language === 0 ? "contacto" : "contact"}
              spy={true}
              smooth={true}
              duration={500}
              onClick={toggleMobilenavVisible}
            >
              {language === 0 ? "CONTACTO" : "CONTACT"}
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MobileNav
